<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách thông tin về người lao động nước ngoài</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} thông tin về người lao động nước ngoài</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách thông tin về người lao động nước ngoài không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- ======================Họ và tên ===================================-->
            <span
              v-if="props.column.field == 'fullName' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'FullName'||!props.row.fullName,dataErr:!props.row.fullName,}"
            >
              {{ props.row.fullName }}
            </span>
            <span v-if="props.column.field == 'fullName' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'FullName'"
                v-model="props.row.fullName"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.fullName, 'fullName', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'FullName'"
              >{{ props.row.fullName }}</span>
            </span>

            <!-- ====================== Ngày sinh ===================================-->
            <span
              v-if="props.column.field == 'birthDay' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'BirthDay'||!props.row.birthDay,dataErr:!props.row.birthDay,}"
            >
              {{ props.row.birthDay }}
            </span>
            <span v-if="props.column.field == 'birthDay' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'BirthDay'"
                v-model="props.row.birthDay"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.birthDay, 'birthDay', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'BirthDay'"
              >{{ props.row.birthDay }}</span>
            </span>

            <!-- ====================== Giới tính===================================-->
            <span
              v-if="props.column.field == 'gender' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Gender'||!props.row.gender,dataErr:!props.row.gender,}"
            >
              {{ props.row.gender }}
            </span>
            <span v-if="props.column.field == 'gender' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Gender'"
                v-model="props.row.gender"
                :reduce="e => e"
                :options="genderList"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.gender, 'gender', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Gender'"
              >{{ props.row.gender }}</span>
            </span>

            <!-- ====================== Ngày cấp ===================================-->
            <span
              v-if="props.column.field == 'datessued' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Datessued'||!props.row.datessued,dataErr:!props.row.datessued,}"
            >
              {{ props.row.datessued }}
            </span>
            <span v-if="props.column.field == 'datessued' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'Datessued'"
                v-model="props.row.datessued"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.datessued, 'datessued', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'Datessued'"
              >{{ props.row.datessued }}</span>
            </span>

            <!-- =========================Quốc tịch============================= -->
            <span
              v-if="props.column.field == 'country' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Country'||!props.row.country,dataErr:!props.row.country,}"
            >
              {{ props.row.country }}
            </span>
            <span v-if="props.column.field == 'country' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Country'"
                v-model="props.row.country"
                :reduce="data => data.name"
                :label="'name'"
                :options="arrContry"
                placeholder="Quốc tịch"
                @input="changeCellvalue(props.row.country, 'country', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Country'"
              >{{ props.row.country }}</span>
            </span>

            <!-- =========================Trình độ============================= -->
            <span
              v-if="props.column.field == 'foreignerEducationLevel' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'ForeignerEducationLevel'||!props.row.foreignerEducationLevel,dataErr:!props.row.foreignerEducationLevel,}"
            >
              {{ props.row.foreignerEducationLevel }}
            </span>
            <span v-if="props.column.field == 'foreignerEducationLevel' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'ForeignerEducationLevel'"
                v-model="props.row.foreignerEducationLevel"
                :reduce="data => data.name"
                :label="'name'"
                :options="foreignerEducationLevel"
                placeholder="Trình độ"
                @input="changeCellvalue(props.row.foreignerEducationLevel, 'foreignerEducationLevel', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'ForeignerEducationLevel'"
              >{{ props.row.foreignerEducationLevel }}</span>
            </span>

            <!-- ====================== Chuyên môn đào tạo ===================================-->
            <span
              v-if="props.column.field == 'foreignerQualification' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'ForeignerQualification'||!props.row.foreignerQualification,dataErr:!props.row.foreignerQualification,}"
            >
              {{ props.row.foreignerQualification }}
            </span>
            <span v-if="props.column.field == 'foreignerQualification' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'ForeignerQualification'"
                v-model="props.row.foreignerQualification"
                :reduce="data => data.name"
                :label="'name'"
                :options="foreignerQualification"
                placeholder="Chuyên môn đào tạo"
                @input="changeCellvalue(props.row.foreignerQualification, 'foreignerQualification', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'ForeignerQualification'"
              >{{ props.row.foreignerQualification }}</span>
            </span>

            <!-- ====================== Ngày cấp giấy phép ===================================-->
            <span
              v-if="props.column.field == 'dateLicense' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'datessued'||!props.row.dateLicense,dataErr:!props.row.dateLicense,}"
            >
              {{ props.row.dateLicense }}
            </span>
            <span v-if="props.column.field == 'dateLicense' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'datessued'"
                v-model="props.row.dateLicense"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.dateLicense, 'dateLicense', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'datessued'"
              >{{ props.row.dateLicense }}</span>
            </span>

            <!-- ====================== Tên doanh nghiệp, tổ chức ===================================-->
            <span
              v-if="props.column.field == 'bussiness' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Bussiness'||!props.row.bussiness,dataErr:!props.row.bussiness,}"
            >
              {{ props.row.bussiness }}
            </span>
            <span v-if="props.column.field == 'bussiness' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Bussiness'"
                v-model="props.row.bussiness"
                :reduce="data => data.name"
                :label="'name'"
                :options="dataAllBusiness || []"
                placeholder="Tên doanh nghiệp, tổ chức"
                @input="changeCellvalue(props.row.bussiness, 'bussiness', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Bussiness'"
              >{{ props.row.bussiness }}</span>
            </span>

            <!-- ====================== Vị trí công việc ===================================-->
            <span
              v-if="props.column.field == 'foreignerJobPosition' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'ForeignerJobPosition'||!props.row.foreignerJobPosition,dataErr:!props.row.foreignerJobPosition,}"
            >
              {{ props.row.foreignerJobPosition }}
            </span>
            <span v-if="props.column.field == 'foreignerJobPosition' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'ForeignerJobPosition'"
                v-model="props.row.foreignerJobPosition"
                :reduce="data => data.name"
                :label="'name'"
                :options="foreignerJobPosition || []"
                placeholder="Vị trí công việc"
                @input="changeCellvalue(props.row.foreignerJobPosition, 'foreignerJobPosition', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'ForeignerJobPosition'"
              >{{ props.row.foreignerJobPosition }}</span>
            </span>

            <!-- ====================== Nghề công việc ===================================-->
            <span
              v-if="props.column.field == 'foreignerJob' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'ForeignerJob'||!props.row.foreignerJob,dataErr:!props.row.foreignerJob,}"
            >
              {{ props.row.foreignerJob }}
            </span>
            <span v-if="props.column.field == 'foreignerJob' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'ForeignerJob'"
                v-model="props.row.foreignerJob"
                :reduce="data => data.name"
                :label="'name'"
                :options="foreignerJob || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.foreignerJob, 'foreignerJob', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'ForeignerJob'"
              >{{ props.row.foreignerJob }}</span>
            </span>

            <!-- ====================== Hình thức làm việc ===================================-->
            <span
              v-if="props.column.field == 'foreignerForm' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'ForeignerForm'||!props.row.foreignerForm,dataErr:!props.row.foreignerForm,}"
            >
              {{ props.row.foreignerForm }}
            </span>
            <span v-if="props.column.field == 'foreignerForm' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'ForeignerForm'"
                v-model="props.row.foreignerForm"
                :reduce="data => data.name"
                :label="'name'"
                :options="foreignerForm || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.foreignerForm, 'foreignerForm', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'ForeignerForm'"
              >{{ props.row.foreignerForm }}</span>
            </span>

            <!-- ====================== Ngày bắt đầu thời hạn làm việc ===================================-->
            <span
              v-if="props.column.field == 'dateStart' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'DateStart'||!props.row.dateStart,dataErr:!props.row.dateStart,}"
            >
              {{ props.row.dateStart }}
            </span>
            <span v-if="props.column.field == 'dateStart' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'DateStart'"
                v-model="props.row.dateStart"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.dateStart, 'dateStart', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'DateStart'"
              >{{ props.row.dateStart }}</span>
            </span>

            <!-- ====================== Ngày kết thúc thời hạn làm việc ===================================-->
            <span
              v-if="props.column.field == 'dateEnd' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'DateEnd'||!props.row.dateEnd,dataErr:!props.row.dateEnd,}"
            >
              {{ props.row.dateEnd }}
            </span>
            <span v-if="props.column.field == 'dateEnd' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'DateEnd'"
                v-model="props.row.dateEnd"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.dateEnd, 'dateEnd', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'DateEnd'"
              >{{ props.row.dateEnd }}</span>
            </span>

            <!-- ====================== Ngày thu thập ===================================-->
            <span
              v-if="props.column.field == 'dateCollect' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'DateCollect'||!props.row.dateCollect,dataErr:!props.row.dateCollect,}"
            >
              {{ props.row.dateCollect }}
            </span>
            <span v-if="props.column.field == 'dateCollect' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'DateCollect'"
                v-model="props.row.dateCollect"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.dateCollect, 'dateCollect', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'DateCollect'"
              >{{ props.row.dateCollect }}</span>
            </span>

            <span
              v-if="props.column.field == 'identityCardNumber'"
            >
              {{ props.row.identityCardNumber }}
            </span>
            <span
              v-if="props.column.field == 'licenseNumber'"
            >
              {{ props.row.licenseNumber }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} thông tin về người lao động nước ngoài</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm thông tin về người lao động nước ngoài
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import store from '../../store'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import ConstantsApi from '../../constants/ConstantsApi'
import enumSelect from '../EnumSelected'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
    DateTimePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      disableProp: false,
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Hộ chiếu',
          field: 'identityCardNumber',
          sortable: false,
        },
        {
          label: 'Ngày cấp',
          field: 'datessued',
          sortable: false,
        },
        {
          label: 'Quốc tịch',
          field: 'country',
          sortable: false,
        },
        {
          label: 'Trình độ',
          field: 'foreignerEducationLevel',
          sortable: false,
        },
        {
          label: 'Chuyên môn đào tạo',
          field: 'foreignerQualification',
          sortable: false,
        },
        {
          label: 'Số giấy phép lao động/xác nhận không thuộc diện cấp giấy phép lao động',
          field: 'licenseNumber',
          sortable: false,
        },
        {
          label: 'Ngày cấp giấy phép',
          field: 'dateLicense',
          sortable: false,
        },
        {
          label: 'Tên doanh nghiệp, tổ chức',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'Vị trí công việc',
          field: 'foreignerJobPosition',
          sortable: false,
        },
        {
          label: 'Nghề công việc',
          field: 'foreignerJob',
          sortable: false,
        },
        {
          label: 'Hình thức làm việc',
          field: 'foreignerForm',
          sortable: false,
        },
        {
          label: 'Ngày bắt đầu thời hạn làm việc',
          field: 'dateStart',
          sortable: false,
        },
        {
          label: 'Ngày kết thúc thời hạn làm việc',
          field: 'dateEnd',
          sortable: false,
        },
        {
          label: 'Ngày thu thập',
          field: 'dateCollect',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Hộ chiếu',
          field: 'identityCardNumber',
          sortable: false,
        },
        {
          label: 'Ngày cấp',
          field: 'datessued',
          sortable: false,
        },
        {
          label: 'Quốc tịch',
          field: 'country',
          sortable: false,
        },
        {
          label: 'Trình độ',
          field: 'foreignerEducationLevel',
          sortable: false,
        },
        {
          label: 'Chuyên môn đào tạo',
          field: 'foreignerQualification',
          sortable: false,
        },
        {
          label: 'Số giấy phép lao động/xác nhận không thuộc diện cấp giấy phép lao động',
          field: 'licenseNumber',
          sortable: false,
        },
        {
          label: 'Ngày cấp giấy phép',
          field: 'dateLicense',
          sortable: false,
        },
        {
          label: 'Tên doanh nghiệp, tổ chức',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'Vị trí công việc',
          field: 'foreignerJobPosition',
          sortable: false,
        },
        {
          label: 'Nghề công việc',
          field: 'foreignerJob',
          sortable: false,
        },
        {
          label: 'Hình thức làm việc',
          field: 'foreignerForm',
          sortable: false,
        },
        {
          label: 'Ngày bắt đầu thời hạn làm việc',
          field: 'dateStart',
          sortable: false,
        },
        {
          label: 'Ngày kết thúc thời hạn làm việc',
          field: 'dateEnd',
          sortable: false,
        },
        {
          label: 'Ngày thu thập',
          field: 'dateCollect',
          sortable: false,
        },
      ],
      comboboxCounselor: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      genderList: [
        'Nam',
        'Nữ',
      ],
      arrContry: [],
      isSelfEmployment: false,
      foreignerQualification: enumSelect.foreignerQualification,
      foreignerEducationLevel: enumSelect.foreignerEducationLevel,
      scaleWorker: enumSelect.scaleWorker,
      foreignVISA: enumSelect.foreignVISA,
      foreignVISAType: enumSelect.foreignVISAType,
      workerLicenseType: enumSelect.workerLicenseType,
      foreignerJobPosition: enumSelect.foreignerJobPosition,
      foreignerJob: enumSelect.foreignerJob,
      foreignerForm: enumSelect.foreignerForm,
    }
  },
  computed: {
    ...mapGetters('collectforeignWorker', ['validWork', 'validNoWork', 'dataTooltip']),
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  async created() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
    this.checkErrorUser(this.dataTooltip)
    const fetchContry = await this.fecthCountry()
    this.arrContry = fetchContry.data
    await this.fetchDataComboboxAllBusiness(this.isSelfEmployment)
  },
  methods: {
    ...mapActions('collectforeignWorker', ['getApiExcel']),
    ...mapActions('worker', ['fecthCountry']),
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'foreign-workers' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      if (this.dataInvalid.length > 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.getApiExcel(model).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Thêm thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.dataInvalid = []
            if (this.dataNoInvalid.length === 0) {
              this.$router.push({ name: 'foreign-workers' })
            }
          } else {
            this.$root.$bvToast.toast('Thêm không thành công', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      } else {
        this.$root.$bvToast.toast('Thêm không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
